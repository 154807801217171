<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Datatable, Pagination },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "-",
      },
      {
        width: "auto",
        label: "NIP",
        name: "NIP",
      },
      {
        width: "auto",
        label: "Name",
        name: "name",
      },
      {
        width: "auto",
        label: "Role",
        name: "-",
      },
      {
        width: "280px",
        label: "Aksi",
        name: "-",
      }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "User",
      isRead: localStorage.getItem("isRead"),
      isWrite: localStorage.getItem("isWrite"),
      loadingTable: true,
      tableData: [],
      columns: columns,
      sortKey: "name",
      sortOrders: sortOrders,
      tableFilter: {
        page: 1,
        per_page: 10,
        search: "",
        column: "user_id",
        dir: "desc",
        status: "all",
        nip: "",
        name: "",
        role: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      formPayload: {},
      optionFilterRole: [],
      modalTambah: false,
      modalEdit: false,
    };
  },
  mounted() {
    if (this.isWrite != 1) this.columns.pop();
    this.filterData = _.debounce(this.getTableData.bind(this), 500);
    this.getFilterRole();
    this.getTableData();
  },
  methods: {
    async getFilterRole() {
      await axios({
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/roles/all",
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      }).then((response) => {
        this.optionFilterRole = response.data.data;
      });
    },
    async getTableData() {
      this.loadingTable = true;
      const data = await axios({
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users",
        params: this.tableFilter,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err get data:", err);
          return false;
        });
      if (data) {
        this.tableData = data.data.data;
        this.pagination.total = data.data.total;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
        this.pagination.lastPageUrl = data.last_page_url;
        this.pagination.nextPageUrl = data.next_page_url;
        this.pagination.prevPageUrl = data.prev_page_url;
        this.pagination.per_page = this.tableFilter.per_page;
        this.pagination.page = this.tableFilter.page;
      }
      this.loadingTable = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getTableData();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getTableData();
    },
    create() {
      this.formPayload = {
        username: "",
        name: "",
        role: null,
        password: "PLNups@123",
        password_confirm: "PLNups@123",
      };
      this.modalTambah = true;
    },
    async store() {
      let payload = { ...this.formPayload };
      payload.selected_role = payload.role.id_role;
      const data = await axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users",
        data: payload,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err save data:", err);
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: err.response.data.data,
          });
          return false;
        });
      if (data) {
        Swal.fire({
          icon: "success",
          title: "Data berhasil disimpan",
        });
        this.getTableData();
        this.modalTambah = false;
      }
    },
    edit(row) {
      this.formPayload = {
        user_id: row.user_id,
        username: row.username,
        name: row.name,
        role: row.user_role?.role,
      };
      this.modalEdit = true;
    },
    async update() {
      let payload = { ...this.formPayload };
      payload.selected_role = payload.role.id_role;
      const data = await axios({
        method: "put",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/users/" +
          payload?.user_id,
        data: payload,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err save data:", err);
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: err.response.data.data,
          });
          return false;
        });
      if (data) {
        Swal.fire({
          icon: "success",
          title: "Data berhasil diperbarui",
        });
        this.getTableData();
        this.modalEdit = false;
      }
    },
    resetPassword(id) {
      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin untuk reset password user ini ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await axios({
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/users/" +
              id +
              "/reset-password",
            headers: {
              Authorization: `Bearer ${localStorage.access_token}`,
            },
          })
            .then((response) => response.data)
            .catch((err) => {
              console.log("err reset password:", err);
              Swal.fire({
                icon: "error",
                text: "Terjadi Kesalahan",
                title: "Silahkan coba kembali beberapa saat lagi",
              });
              return false;
            });
          if (data) {
            Swal.fire({
              icon: "success",
              title: "Password Ter-reset",
            });
            this.getTableData();
          }
        }
      });
    },
    destroy(id) {
      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin menghapus data ini ?",
        text: "Data yang dihapus tidak dapat dipulihkan kembali.",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await axios({
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/" + id,
            headers: {
              Authorization: `Bearer ${localStorage.access_token}`,
            },
          })
            .then((response) => response.data)
            .catch((err) => {
              console.log("err delete data:", err);
              Swal.fire({
                icon: "error",
                text: "Terjadi Kesalahan",
                title: "Silahkan coba kembali beberapa saat lagi",
              });
              return false;
            });
          if (data) {
            Swal.fire({
              icon: "success",
              title: "Data Berhasil dihapus",
            });
            this.getTableData();
          }
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="text-end">
                  <button
                    class="btn btn-success btn-sm"
                    @click="create()"
                    v-if="isWrite == 1"
                  >
                    <i class="fa fa-plus me-1"></i> Tambah
                  </button>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <hr />
              <div class="form-group col-md-3">
                <label for="filterNIP">NIP</label>
                <input
                  type="text"
                  id="filterNIP"
                  class="form-control"
                  placeholder="Filter NIP"
                  v-model="tableFilter.nip"
                  @input="filterData"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="filterName">Name</label>
                <input
                  type="text"
                  id="filterName"
                  class="form-control"
                  placeholder="Filter Name"
                  v-model="tableFilter.name"
                  @input="filterData"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="filterRole">Role</label>
                <v-select
                  id="filterRole"
                  class="style-chooser"
                  :options="optionFilterRole"
                  :reduce="(role) => role.role_name"
                  label="role_name"
                  v-model="tableFilter.role"
                  placeholder="Filter Role"
                  :deselectFromDropdown="true"
                  @option:selected="filterData"
                  @option:deselected="filterData"
                />
              </div>
            </div>
            <div class="row mt-2">
              <hr />
              <div class="col-12">
                <table class="mb-2">
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        v-model="tableFilter.per_page"
                        @change="getTableData()"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <Datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="99" class="text-center">
                      <i class="fas fa-spinner fa-spin"></i>
                      Loading
                    </td>
                  </tr>
                  <tr v-else-if="tableData.length < 1">
                    <td colspan="99" class="text-center">Data Empty</td>
                  </tr>
                  <tr v-else v-for="(row, index) in tableData" :key="index">
                    <td>{{ index + pagination.from }}</td>
                    <td>{{ row.username }}</td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.user_role?.role?.role_name || "-" }}</td>
                    <td v-if="isWrite == 1">
                      <div class="btn-group">
                        <button
                          class="btn btn-info btn-sm"
                          @click="resetPassword(row.user_id)"
                          v-if="isWrite == 1"
                        >
                          <i class="fa fa-recycle me-1"></i>
                          Reset Password
                        </button>
                        <button
                          class="btn btn-warning btn-sm"
                          @click="edit(row)"
                          v-if="isWrite == 1"
                        >
                          <i class="fa fa-edit me-1"></i>
                          Edit
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="destroy(row.user_id)"
                          v-if="isWrite == 1"
                        >
                          <i class="fa fa-trash me-1"></i> Hapus
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Datatable>
              <Pagination
                :pagination="pagination"
                @to-page="toPage"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="modalTambah" hide-footer title="Tambah Pengguna Aplikasi">
    <form @submit.prevent="store" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="formUsername">NIP</label>
        <input
          type="text"
          id="formUsername"
          class="form-control"
          placeholder="NIP..."
          v-model="formPayload.username"
        />
      </div>
      <div class="mb-3">
        <label for="formNama">Nama</label>
        <input
          type="text"
          id="formNama"
          class="form-control"
          placeholder="Nama..."
          v-model="formPayload.name"
        />
      </div>
      <div class="mb-3">
        <label for="formUsername">Role</label>
        <v-select
          id="filterRole"
          class="style-chooser"
          :options="optionFilterRole"
          label="role_name"
          v-model="formPayload.role"
          placeholder="Role"
          :deselectFromDropdown="true"
        />
      </div>
      <div class="mb-3">
        <label for="formPassword">Password</label>
        <input
          type="text"
          id="formPassword"
          class="form-control"
          placeholder="Pelaksanaan..."
          v-model="formPayload.password"
          :disabled="true"
        />
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-sm">
          <i class="fa fa-save"></i> Simpan
        </button>
      </div>
    </form>
  </b-modal>

  <b-modal v-model="modalEdit" hide-footer title="Edit Pelaksanaan">
    <form @submit.prevent="update" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="formUsername">NIP</label>
        <input
          type="text"
          id="formUsername"
          class="form-control"
          placeholder="NIP..."
          v-model="formPayload.username"
        />
      </div>
      <div class="mb-3">
        <label for="formNama">Nama</label>
        <input
          type="text"
          id="formNama"
          class="form-control"
          placeholder="Nama..."
          v-model="formPayload.name"
        />
      </div>
      <div class="mb-3">
        <label for="formUsername">Role</label>
        <v-select
          id="filterRole"
          class="style-chooser"
          :options="optionFilterRole"
          label="role_name"
          v-model="formPayload.role"
          placeholder="Role"
          :deselectFromDropdown="true"
        />
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-sm">
          <i class="fa fa-save"></i> Simpan
        </button>
      </div>
    </form>
  </b-modal>
</template>
